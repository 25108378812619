<template>
  <div class="cainet-info">
    <el-button type="text" size="medium" @click.stop="onInfo()">查看详情</el-button>
    <!-- 详情弹框 -->
    <el-dialog :title="row.deviceName" :visible.sync="infoShow" :close-on-click-modal="false" append-to-body width="50%">
      <div class="top">
        <el-button type="primary" @click="onAllOpen">全部打开</el-button>
        <el-button type="primary" @click="onAlladd">全部补货</el-button>
      </div>
      <div class="mid">
        <span><i class="icon-w"></i>销售中</span>
        <span><i class="icon-r"></i>已售罄</span>
      </div>
      <div class="bottom">
        <li class="items" v-for="item in cabinetItem" :key="item.goodsShelvesId"
          :class="{ 'sell-out': item.status == 2 }">
          <span class="num">{{ item.goodsShelvesName }}</span>
          <div class="con" v-if="item.salesCabinetGoodsVo">
            <p class="con-top">
              <el-image class="con-img" :src="item.salesCabinetGoodsVo.imageUrl">
              </el-image>
              <span class="con-name">{{ item.salesCabinetGoodsVo.goodsName }}</span>
            </p>
            <p class="con-bot">
              <span class="con-price">￥{{ item.salesCabinetGoodsVo.salesPrice }}</span>
              <span class="con-icon">
                <i class="iconfont icon-line-exchange" @click="onEdit(item)"></i>
                <i class="iconfont icon-icon_xq" @click="onOperation(item)"></i>
              </span>
            </p>
          </div>
          <div v-else class="add">
            <el-button type="text" size="medium" @click.stop="onAdd(item)">
              <i class="el-icon-plus"></i>添加商品
            </el-button>
          </div>
        </li>
      </div>
    </el-dialog>
    <!-- 编辑弹框 -->
    <el-dialog :title="cabinet.goodsShelvesName" :visible.sync="editShow" :close-on-click-modal="false" append-to-body
      width="30%">
      <el-form :model="params" :rules="rules" ref="form-ref" inline>
        <section class="form-main">
          <el-form-item label="选择商品" prop="shopGoodsId">
            <el-select v-model="params.shopGoodsId" placeholder="请选择商品" clearable :disabled="editType == 2"
              @change="onGoods">
              <el-option v-for="item in goodsList" :key="item.shopGoodsId" :label="item.goodsName"
                :value="item.shopGoodsId">
              </el-option>
            </el-select>
          </el-form-item>
          <div class="goods-info" v-if="params.goodsName">
            <el-image style="width: 1rem; height: 1rem" :src="params.imageUrl" :preview-src-list="[params.imageUrl]">
            </el-image>
            <div class="info-text">
              <div>
                <p>{{ params.goodsName }}</p>
                <p class="info-red">
                  <span>销售价格：￥{{ params.salesPrice }}</span>
                  <span>实际销量：{{ params.salesVolume }}</span>
                </p>
              </div>
              <div>
                <el-tag type="success" v-if="cabinet.status == 1">售卖中</el-tag>
                <el-tag type="danger" v-if="cabinet.status == 2">已售罄</el-tag>
              </div>
            </div>
          </div>
          <el-form-item label="商品库存" prop="inventory">
            <el-input v-model="params.inventory" autocomplete="off" maxlength="5" placeholder="请填写商品库存"
              oninput="value=value.replace(/[^\d]/g,'')" clearable>
            </el-input>
            <span>货格最大容量为{{ cabinet.goodsShelvesCapacity }}</span>
          </el-form-item>
        </section>
        <section class="form-footer">
          <el-button type="primary" @click="onSubmit('form-ref')">保存修改</el-button>
          <el-button type="primary" @click="onOpen()">打开货柜</el-button>
          <el-button type="primary" @click="onDel()" v-if="editType == 2">解绑商品</el-button>
          <el-button type="primary" @click="onSellOut()" v-if="editType == 2">设为售罄</el-button>
        </section>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCabinetItem,
  getMallGoodsList,
  getCabinetEdit,
  getCabinetDel,
  getCabinetAdd,
  getCabinetSellOut,
  getCabinetAllAdd,
  getCabinetOpen,
} from "@/api/mall/mall";
import { rules } from "@/db/rules";
export default {
  props: ['row'],
  data() {
    return {
      goodsList: [],
      cabinetItem: [],
      // 详情弹框
      infoShow: false, //弹框开关
      // 编辑弹框
      editShow: false, //弹框开关
      editTitle: "",
      editStatus: "",
      editType: "",
      rules,
      cabinet: {},
      params: {
        shopGoodsId: "",//商品ID
        imageUrl: "",//商品图片
        goodsName: "",//商品名称
        salesPrice: "",//售价
        salesVolume: "",//销量
        inventory: "",//库存
      },
    }
  },
  methods: {
    // 【请求】查询售卖柜设备详情
    getCabinetItem() {
      let data = this.row;
      getCabinetItem(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.cabinetItem = res.data;
        }
      });
    },

    // 【请求】门店商品列表
    getMallGoodsList() {
      let merchantShopId = window.localStorage.getItem('playOne-store-merchantShopId');
      let data = {
        page: 1,
        pageSize: 0,
        merchantId: JSON.parse(merchantShopId)[0],//商户ID
        shopId: JSON.parse(merchantShopId)[1],//店铺ID
      };
      getMallGoodsList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.goodsList = res.data.list;
        }
      });
    },

    // 【请求】编辑
    getCabinetEdit() {
      let data = this.params;
      getCabinetEdit(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.editShow = false;
          this.getCabinetItem();// 【请求】表格数据
        }
      });
    },

    // 【请求】新增
    getCabinetAdd() {
      let merchantShopId = window.localStorage.getItem('playOne-store-merchantShopId');
      let data = this.cabinet;
      data.shopGoodsId = this.params.shopGoodsId;
      data.inventory = this.params.inventory;
      data.merchantId = JSON.parse(merchantShopId)[0];
      data.shopId = JSON.parse(merchantShopId)[1];
      data.deviceId = this.row.deviceId;
      getCabinetAdd(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.editShow = false;
          this.getCabinetItem();// 【请求】表格数据
        }
      });
    },

    // 【监听】查看详情
    onInfo() {
      this.getCabinetItem();
      this.getMallGoodsList();
      this.infoShow = true;
    },

    // 【监听】编辑
    onEdit(item) {
      this.editShow = true;
      this.editType = 1;
      this.cabinet = JSON.parse(JSON.stringify(item));
      this.params = JSON.parse(JSON.stringify(item.salesCabinetGoodsVo));
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },

    // 【监听】操作
    onOperation(item) {
      this.editShow = true;
      this.editType = 2;
      this.cabinet = JSON.parse(JSON.stringify(item));
      this.params = JSON.parse(JSON.stringify(item.salesCabinetGoodsVo));
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },

    // 【监听】添加商品
    onAdd(item) {
      this.editShow = true;
      this.editType = 3;
      this.cabinet = JSON.parse(JSON.stringify(item));
      this.params = {
        shopGoodsId: "",//商品ID
        imageUrl: "",//商品图片
        goodsName: "",//商品名称
        salesPrice: "0",//售价
        salesVolume: "0",//销量
        inventory: "",//库存
      };
    },

    // 【监听】商品切换
    onGoods(val) {
      for (let i of this.goodsList) {
        if (i.shopGoodsId == val) {
          this.params.goodsName = i.goodsName;
          this.params.salesPrice = i.salesPrice;
          this.params.imageUrl = i.imageUrl;
          this.params.salesVolume = i.salesVolume;
          this.params.inventory = "";
        }
      }
    },

    // 【监听】解绑商品
    onDel() {
      let data = this.params;
      this.$confirm('你确定要解绑商品吗?', '解绑商品', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(() => {
        getCabinetDel(data).then(res => {
          if (res.isSuccess == "yes") {
            this.$message({
              message: res.message,
              type: "success",
            });
            this.editShow = false;
            this.getCabinetItem();
          }
        })
      });
    },

    // 【监听】设为售罄
    onSellOut() {
      let data = this.params;
      this.$confirm('你确定要设为售罄吗?', '设为售罄', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(() => {
        getCabinetSellOut(data).then(res => {
          if (res.isSuccess == "yes") {
            this.$message({
              message: res.message,
              type: "success",
            });
            this.editShow = false;
            this.getCabinetItem();
          }
        })
      });
    },

    // 【监听】全部补货
    onAlladd() {
      let merchantShopId = window.localStorage.getItem('playOne-store-merchantShopId');
      let data = {
        merchantId: JSON.parse(merchantShopId)[0],
        shopId: JSON.parse(merchantShopId)[1],
        deviceId: this.row.deviceId
      }
      this.$confirm('你确定要全部补货吗?', '全部补货', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(() => {
        getCabinetAllAdd(data).then(res => {
          if (res.isSuccess == "yes") {
            this.$message({
              message: res.message,
              type: "success",
            });
            this.editShow = false;
            this.getCabinetItem();
          }
        })
      });
    },

    // 【监听】打开全部货柜
    onAllOpen() {
      let data = {
        deviceId: this.row.deviceId,
      };
      this.$confirm('你确定要打开全部货柜吗?', '打开全部货柜', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(() => {
        getCabinetOpen(data).then(res => {
          if (res.isSuccess == "yes") {
            this.$message({
              message: res.message,
              type: "success",
            });
            this.editShow = false;
            this.getCabinetItem();
          }
        })
      });
    },
    // 【监听】打开货柜
    onOpen() {
      let data = {
        deviceId: this.row.deviceId,
        goodsShelvesId: this.params.goodsShelvesId
      };
      this.$confirm('你确定要打开货柜吗?', '打开货柜', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(() => {
        getCabinetOpen(data).then(res => {
          if (res.isSuccess == "yes") {
            this.$message({
              message: res.message,
              type: "success",
            });
            this.editShow = false;
            this.getCabinetItem();
          }
        })
      });
    },

    // 【监听】表单提交
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.params.goodsShelvesId) {
            this.getCabinetEdit();
          } else {
            this.getCabinetAdd()
          }
        } else {
          return false;
        }
      });
    },

  }
}
</script>

<style lang="scss" scoped>
.cainet-info {
  display: inline-flex;
  margin-left: .1rem;
}

.form-footer {
  display: flex;
  justify-content: flex-end;
}

.mid {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding: .15rem 0;

  span {
    margin-left: .15rem;
    display: flex;
    align-items: center;
  }
}

.bottom {
  display: flex;
  flex-wrap: wrap;
  padding: .15rem 0;
}

.items {
  width: 2rem;
  display: inline-flex;
  flex-direction: column;
  border: 1px solid #ddd;
  margin: 0 .15rem .15rem 0;
}

.num {
  padding: .15rem;
  border-bottom: 1px solid #ddd;
  font-size: .16rem;
}

.icon-w {
  display: inline-block;
  width: .16rem;
  height: .16rem;
  border: 1px solid #ddd;
  margin-right: .1rem;
}

.icon-r {
  display: inline-block;
  width: .16rem;
  height: .16rem;
  border: 1px solid rgba(255, 25, 25, 0.30);
  background: rgba(255, 25, 25, 0.08);
  margin-right: .1rem;
}

.con-name {
  padding: 0 .15rem;
  font-size: .14rem;
}

.con-top {
  padding: .15rem;
  display: flex;
  align-items: flex-start;
}

.con-img {
  width: .4rem;
  height: .4rem;
  box-sizing: border-box;
  border: 1px solid #ddd;
}

.con-bot {
  padding: .15rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: .16rem;
}

.con-price {
  color: #FF1919;
}

.con-icon {
  display: flex;
  align-items: center;
}

.iconfont {
  margin-left: .1rem;
  cursor: pointer;
  font-size: .18rem;
}

.goods-info {
  display: flex;
  margin-bottom: .15rem;
}

.info-text {
  margin-left: .15rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.info-red {
  color: #FF1919;

  span {
    margin-right: .15rem;
  }
}

.add {
  min-height: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sell-out {
  border: 1px solid rgba(255, 25, 25, 0.30);
  background: rgba(255, 25, 25, 0.08);
  color: #FF1919;
}
</style>