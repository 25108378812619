<template>
  <div class="cabinet">
    <!-- Table表格 -->
    <el-table
      :data="tableData"
      border
      stripe
      :header-cell-style="{
        background: '#FAFAFA',
        color: '#000000',
      }"
    >
      <el-table-column label="名称" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.deviceName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="设备位置" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.deviceAddress || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="总格数 / 在售 / 售罄 / 未绑定" align="center">
        <template slot-scope="scope">
          <span>{{
            (scope.row.goodsShelvesTotalVo &&
              scope.row.goodsShelvesTotalVo.total) ||
            "0"
          }}</span>
          <span>&nbsp;/&nbsp;</span>
          <span>{{
            (scope.row.goodsShelvesTotalVo &&
              scope.row.goodsShelvesTotalVo.onSale) ||
            "0"
          }}</span>
          <span>&nbsp;/&nbsp;</span>
          <span>{{
            (scope.row.goodsShelvesTotalVo &&
              scope.row.goodsShelvesTotalVo.out) ||
            "0"
          }}</span>
          <span>&nbsp;/&nbsp;</span>
          <span>{{
            (scope.row.goodsShelvesTotalVo &&
              scope.row.goodsShelvesTotalVo.not) ||
            "0"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="medium"
            @click.stop="onEdit(1, scope.row)"
            >修改信息</el-button
          >
          <el-button
            type="text"
            size="medium"
            @click.stop="onEdit(2, scope.row)"
            >二维码导出</el-button
          >
          <MallCainetInfo :row="scope.row"></MallCainetInfo>
        </template>
      </el-table-column>
    </el-table>
    <!-- Table分页 -->
    <section class="table-footer">
      <el-pagination
        layout="total,sizes, prev, pager, next,jumper"
        background
        :total="total"
        :page-size="tableParams.pageSize"
        @size-change="onSizeChange"
        :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage"
        @current-change="onCurrentPage"
      >
      </el-pagination>
    </section>
    <!-- 编辑弹框 -->
    <el-dialog
      title="售卖柜信息"
      :visible.sync="editShow"
      :close-on-click-modal="false"
      append-to-body
      width="30%"
    >
      <el-form
        :model="params"
        :rules="rules"
        ref="form-ref"
        label-width="100px"
      >
        <section class="form-main">
          <el-form-item label="售卖柜名称" prop="deviceName">
            <el-input
              v-model.trim="params.deviceName"
              autocomplete="off"
              maxlength="10"
              placeholder="请填写售卖柜名称"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="售卖柜描述" prop="deviceDetails">
            <el-input
              type="textarea"
              :rows="3"
              v-model.trim="params.deviceDetails"
              autocomplete="off"
              maxlength="200"
              placeholder="请填写售卖柜描述"
            ></el-input>
          </el-form-item>
          <el-form-item label="售卖柜图片" prop="imageId">
            <div class="upload-btn" @click="onUpload()">
              <img
                v-if="params.imageUrl"
                :src="params.imageUrl"
                class="upload-img"
              />
              <i v-else class="el-icon-plus uploader-icon"></i>
            </div>
          </el-form-item>
        </section>
        <section class="form-footer">
          <el-button type="primary" @click="onSubmit('form-ref')"
            >保存</el-button
          >
        </section>
      </el-form>
    </el-dialog>
    <!-- 图片上传组件 -->
    <UpLoad @fileIds="getFileIds" ref="up" :fileType="1"></UpLoad>
    <!-- 导出进度弹框 -->
    <el-dialog
      title="售卖柜二维码"
      :visible.sync="exportShow"
      center
      :close-on-click-modal="false"
      append-to-body
      width="20%"
    >
      <div class="export">
        <el-progress
          class="mb15 export-success"
          type="circle"
          text-color="#13CE66"
          :percentage="100"
          status="success"
          v-if="progressInfo.complete"
        ></el-progress>
        <el-progress
          class="mb15"
          type="circle"
          color="#EF9807"
          :percentage="percentage"
          :format="format"
          v-else
        ></el-progress>
        <p class="mb15">
          <b style="color: #13ce66" v-if="progressInfo.complete">已完成</b>
          <b style="color: #ef9807" v-else>进行中</b>
        </p>
        <el-button v-if="this.downloadUrl" @click="onDown">下载</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCabinetList,
  getCabinetDeviceEdit,
  goDealGoodsShelves,
  getGoodsShelvesProgress,
  goDownloadGoodsShelves,
} from "@/api/mall/mall";
import { rules } from "@/db/rules";
import MallCainetInfo from "./child/MallCainetInfo.vue";
import UpLoad from "@/components/upload";
import { getFileList } from "@/api/common";
export default {
  components: { MallCainetInfo, UpLoad },
  data() {
    return {
      // table表格
      currentPage: 1, // 当前页
      total: 0, // 总条数
      tableData: [], //表格数据
      tableParams: {
        page: 1,
        pageSize: 10,
        kw: "", //查询关键字
        merchantId: "", //商户ID
        shopId: "", //店铺ID
      },
      // 编辑弹框
      editShow: false, //弹框开关
      // from表单
      rules,
      params: {
        deviceName: "",
        deviceDetails: "",
        imageId: "", //图片id
        imageUrl: "", //图片
      },
      exportShow: false, //二维码导出
      percentage: 0,
      progressInfo: {
        complete: false,
        num: 0,
        total: 0,
      },
      downloadUrl: "",
    };
  },
  created() {
    let merchantShopId = window.localStorage.getItem(
      "playOne-store-merchantShopId"
    );
    if (merchantShopId) {
      this.tableParams.merchantId = JSON.parse(merchantShopId)[0];
      this.tableParams.shopId = JSON.parse(merchantShopId)[1];
    }
  },
  mounted() {
    this.getCabinetList(); // 【请求】表格数据
  },
  methods: {
    format() {
      return `${this.progressInfo.num}/${this.progressInfo.total}`;
    },
    // 【监听】位置码下载
    onDown() {
      window.location.href = this.downloadUrl;
    },
    // 【请求】处理二维码
    getMiniDeal(deviceId) {
      let data = {
        deviceId: deviceId,
      };
      goDealGoodsShelves(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message(res.data);
          this.getMiniProgress(deviceId); //获取处理进度
        }
      });
    },

    // 【请求】获取处理进度
    getMiniProgress(deviceId) {
      let data = {
        deviceId: deviceId,
      };
      getGoodsShelvesProgress(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.progressInfo = res.data;
          this.percentage = Math.round((res.data.num / res.data.total) * 100);
          if (res.data.complete) {
            this.getDownloadUrl(deviceId);
          } else {
            setTimeout(() => {
              this.getMiniProgress(deviceId);
            }, 1000);
          }
        }
      });
    },

    // 【请求】获取下载链接
    getDownloadUrl(deviceId) {
      let data = {
        deviceId: deviceId,
      };
      goDownloadGoodsShelves(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.downloadUrl = res.data;
        }
      });
    },

    // 【请求】表格数据
    getCabinetList() {
      let data = this.tableParams;
      getCabinetList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },

    // 【请求】编辑
    getCabinetDeviceEdit() {
      let data = this.params;
      getCabinetDeviceEdit(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.editShow = false;
          this.getCabinetList(); // 【请求】表格数据
        }
      });
    },

    // 【监听】表格模糊查询
    kwChange: _.debounce(function () {
      this.reload();
    }, 500),

    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getCabinetList();
    },

    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getCabinetList();
    },

    // 【监听】表格条数点击
    onSizeChange(pageSize) {
      this.tableParams.pageSize = pageSize;
      this.reload();
    },

    // 【监听】编辑
    onEdit(type, row) {
      if (type == 1) {
        this.editShow = true;
        this.params = JSON.parse(JSON.stringify(row));
        this.$nextTick(() => {
          this.$refs["form-ref"].clearValidate();
        });
      } else {
        this.exportShow = true;
        this.percentage = 0;
        this.progressInfo = {
          complete: false,
          num: 0,
          total: 0,
        };
        this.downloadUrl = "";
        this.getMiniDeal(row.deviceId); //处理位置码
      }
    },

    // 【监听】文件上传
    onUpload() {
      this.$refs.up.onUpload();
    },

    // 【监听】获取上传文件ID集合
    getFileIds(fileIds) {
      let data = {
        fileIds,
      };
      getFileList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.params.imageId = fileIds;
          this.params.imageUrl = res.data[0].url;
          this.$forceUpdate();
        }
      });
    },

    // 【监听】表单提交
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.getCabinetDeviceEdit();
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.cabinet {
  padding: 0.15rem;
}

.table-footer {
  margin-top: 0.1rem;
  display: flex;
  justify-content: flex-end;
}

.form-footer {
  display: flex;
  justify-content: flex-end;
}

.upload-btn {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  width: 1.6rem;
  height: 1.6rem;
}

.upload-btn:hover {
  border-color: #409eff;
}

.uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 1.6rem;
  height: 1.6rem;
  line-height: 1.6rem;
  text-align: center;
}

.upload-img {
  width: 1.6rem;
  height: 1.6rem;
  display: block;
}
.export {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
<style lang="scss">
.export-success {
  .el-icon-check {
    font-size: 0.48rem;
  }
}
</style>